
<template>
    <div class="mx-auto" style="max-width: 1000px;">
        <Card class="card-custom">
            <template #title>
                <div class="column-wrapper align-center">
                  <div class="column-three text-left"><back-button /></div>
                    <h4 class="c-black flex-1 column-three"> ID: {{ getIdFromUrl() }}</h4>
                    <div class="column-three"></div>
                </div>
              </template>
          <template #content>
            <ValidationObserver ref="observer" v-slot="{ invalid, validate }" v-if="!errorBonus">
                <div class="space-y-1">
                    <Skeleton width="100%" height="500px" v-if="loadingPage"></Skeleton>
                        <Card class="card-custom form-block" v-if="!loadingPage">
                            <template #title>
                              <h4>
                                {{ $t('bet') }}
                              </h4>
                              </template>
                            <template #content>
                              <div class="space-y-1">
                            <ValidationProvider
                        :name="$t('currency')"
                        rules="required"
                        v-slot="{ errors, validate, validated }"
                      > 
                        <h4 class="c-text white-space title-input">{{ $t('currency') }}<span class="c-red">*</span>:</h4>
                        <div class="wrapper-input d-flex flex-column">
                          <MultiSelect
                          :disabled="isAllCurrencies"
                        v-model="selectedCurrencies"
                        :options="currencies"
                        class="custom-dropdown fw"
                        style="flex: 1 1 auto"
                        :class="{ 'p-invalid' : errors.length > 0 }"
                        :placeholder="$t('currency')"
                        :filter="true"
                        :emptyFilterMessage="$t('no_data')"
                      >
                      </MultiSelect>
                          <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    <span v-for="(limits, currency) in editBonus.currency_bet" :key="currency">
                              <h4 class="c-text white-space title-input">
                                {{ currency }}:
                              </h4>
                                <div class="d-flex md-flex-column gap-1 fw">
                                  <ValidationProvider
                                  tag="div"
                                  class="d-flex flex-column fw"
                                    :name="$t('bet')"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                  <InputNumber 
                                  locale="en-US"
                                  class="flex-auto"
                                  mode="currency"
                                        :currency="currency"
                                        currencyDisplay="code"
                                        :placeholder="$t('bet')"
                                        v-if="selectedCurrencies.includes(currency)"
                                        v-model="editBonus.currency_bet[currency]"
                                        :step="1"
                                        :min="1"
                                      />
                                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                          </span>
                          <Divider />
            <ValidationProvider
                :name="$t('provider')"
                rules=""
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('provider') }}:</h4>
                <div class="wrapper-input d-flex flex-column">
                  <Dropdown
                  optionLabel="name"
              optionValue="string_id"
              dataKey="id"
              @change="selectProvider(null)"
                v-model="provider"
                :options="providers"
                class="custom-dropdown fw"
                style="flex: 1 1 auto"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('provider')"
                :filter="true"
                :emptyFilterMessage="$t('no_data')"
              >
              </Dropdown>
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('game')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('game') }}
                    <span class="ml-1" v-if="editBonus.game_id"> (ID:{{ editBonus.game_id }})</span>
                    <span class="c-red">*</span>:</h4>
                <div class="wrapper-input d-flex flex-column">
                  <Dropdown
                  :disabled="!provider"
                  optionLabel="name"
                  optionValue="id"
                v-model="editBonus.game_id"
                :options="games"
                class="custom-dropdown fw"
                style="flex: 1 1 auto"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="!provider ? $t('select_provider') : $t('choose_game')"
                :filter="true"
                :emptyFilterMessage="$t('no_data')"
              >
              </Dropdown>
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('bonus_type')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('bonus_type') }}
                    <span class="c-red">*</span>:</h4>
                <div class="wrapper-input d-flex flex-column">
                  <Dropdown
                  optionLabel="name"
                  optionValue="value"
                v-model="editBonus.type"
                :options="bonusTypes"
                class="custom-dropdown fw"
                style="flex: 1 1 auto"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('bonus_type')"
                :filter="true"
                :emptyFilterMessage="$t('no_data')"
              >
              </Dropdown>
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('type')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('type') }}
                    <span class="c-red">*</span>:</h4>
                <div class="wrapper-input d-flex flex-column">
                  <Dropdown
                  optionLabel="text"
                  optionValue="value"
                v-model="editBonus.sub_type"
                :options="subTypes"
                class="custom-dropdown fw"
                style="flex: 1 1 auto"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('type')"
                :filter="true"
                :emptyFilterMessage="$t('no_data')"
              >
              </Dropdown>
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('lines')"
                rules="required|min:1"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('lines') }}<span class="c-red">*</span>:</h4>
                <div class="wrapper-input">
                    <InputNumber 
                class="fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('lines')" 
                v-model="editBonus.lines" 
                showButtons  
                :step="1"
                :min="1"
                />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('max_win')"
                rules="required|min:1"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('max_win') }}<span class="c-red">*</span>:</h4>
                <div class="wrapper-input">
                    <InputNumber 
                class="fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('max_win')" 
                v-model="editBonus.max_win" 
                showButtons  
                :step="1"
                :min="1"
                />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('wager')"
                rules="required|min:1"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('wager') }}<span class="c-red">*</span>:</h4>
                <div class="wrapper-input">
                    <InputNumber 
                class="fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('wager')" 
                v-model="editBonus.wager" 
                prefix="x"
                showButtons  
                :step="1"
                :min="1"
                />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider
                :name="$t('expire_in_days')"
                rules="required|min:1"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('expire_in_days') }}<span class="c-red">*</span>:</h4>
                <div class="wrapper-input">
                    <InputNumber 
                class="fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('expire_in_days')" 
                v-model="editBonus.expire_in_days" 
                showButtons  
                :step="1"
                :min="1"
                />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <span> 
                <h4 class="c-text white-space title-input">
                  {{ $t('active') }}: 
                </h4>
                <div class="d-flex flex-column">
                  <InputSwitch class="p-inputswitch-success" v-model="editBonus.is_enabled" />
                </div>
              </span>
            </div>
          </template>
            </Card>
                    </div>
                    <Button @click="handleSubmit(validate)" 
            :disabled="formLoading || loadingFlag" 
            :loading="formLoading || loadingFlag"
            :label="$t('edit')"
                    icon="pi pi-save" class="p-button-sm p-button-success fw mt-2" />
            </ValidationObserver>
            <no-work-page 
        v-else
        @refresh-request="toGetBonusByID" 
        :loadingFlag="loadingFlag"
        textError="just_error"
        />
    </template>
    </Card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Api from "@/components/api";
export default {
    name: 'EditSpinsBonus',
    computed: {
        ...mapGetters({
            editBonus: 'bonuses/getSelectedSpinsBonus',
            providers: 'providers/getFilteredProviders',
            currencies: 'getFilteredCurrencies',
        }),
    },
    watch: {
        selectedCurrencies(newCurrencies) {
      if (!this.editBonus.currency_bet) {
        this.$set(this.editBonus, 'currency_bet', {});
      }
      for (const currency in this.editBonus.currency_bet) {
        if (!newCurrencies.includes(currency)) {
          delete this.editBonus.currency_bet[currency];
        }
      }
      newCurrencies.forEach(currency => {
        if (!this.editBonus.currency_bet[currency]) {
          this.$set(this.editBonus.currency_bet, currency, '');
        }
      });
    },
    editBonus: {
      handler(newBonus, oldBonus) {
        // editBonus
        // selectedCurrencies
        if (newBonus && newBonus.currency_bet) {
            
            this.selectedCurrencies = [];
            Object.keys(newBonus.currency_bet).forEach(currency => {
                this.selectedCurrencies.push(currency);
            });
        }
         if (newBonus && newBonus.provider_name) {
             this.selectProvider(newBonus.provider_name);
             }
      },
      deep: true,
      immediate: true
    },
    'editBonus.provider_name': {
        handler(newProvider, oldProvider) {
            if (newProvider) {
                this.selectProvider(newProvider);
            }
        },
        immediate: true
    },
        isAllCurrencies(newValue) {
            if (newValue) {
                // add currencies
                const currencies = Object.keys(this.currencies).map(currency => {
                    return this.currencies[currency]; // Используйте название валюты вместо индекса
                });
                this.selectedCurrencies = currencies;
                this.selectedCurrencies = currencies;
            } else {
                // to delete from selectedCurrencies
                this.selectedCurrencies = [];
                this.selectedCurrencies = [];
            }
        },
    },
    data() {
        return {
            games: [],
            provider: '',
            selectedCurrencies: [
            ],
            errorBonus: false,
            loadingFlag: false,
            loadingPage: true,
            formLoading: false,
            isAllCurrencies: false,
            subTypes: [
          { text: 'Non Sticky', value: 'NON_STICKY' },
            { text: 'Sticky', value: 'STICKY' }
        ],
            bonusTypes: [
                { name: this.$t('free_rounds'), value: 'free_rounds' },
                { name: this.$t('welcome'), value: 'welcome' },
            ],
        }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'edit_spins_bonus')
    });
    },
    async mounted() {
        // this.editBonus = this.bonusInfo;
        this.loadingPage = true;
        const id = this.getIdFromUrl();
        if (this.editBonus.id !== id) {
            await this.toGetBonusByID();
        }
        if (!this.providers.length) {
            await this.$store.dispatch('providers/awaitGameProviders', {});
        }
        this.loadingPage = false;
    },
    methods: {
        getIdFromUrl() {
              const url = window.location.pathname;
              const parts = url.split('/'); 
              return Number(parts[parts.length - 1]);
            },
        selectProvider(newProv = null) {
            let providerValue;
            if (!newProv) {
                this.editBonus.game_id = null;
            }
            if (this.provider === '') {
                providerValue = this.editBonus.provider_name;
            } else {
                providerValue = this.provider;
            }
            if (providerValue !== null) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    },
                };

                const params = {
                    provider: providerValue,
                    size: 100,
                };
                this.provider = providerValue;
                axios
                    .get('/main-api/games/admin/filter', { params, headers: config.headers })
                    .then(response => {
                        const items = response.data.items;
                        const games = items.map(item => item.Game);
                        this.games = games;
                    })
                    .catch(error => {
                        console.error(error);
                        this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
                        detail: error,
                        life: 4000 }); 
                    });
            }
        },
        async toGetBonusByID() {
        this.errorBonus = false;
        this.loadingFlag = true;
        try {
          const id = this.getIdFromUrl();
          await this.$store.dispatch('bonuses/getSpinsBonusByID', id);
        } catch (error) {
          this.errorBonus = true;
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
        } finally {
          this.loadingFlag = false;
        }
      },
        handleSubmit(validate) {
            validate().then((success) => {
                if (success) {
                    this.confirmEditBonus();
                }
            });
        },
        async confirmEditBonus(){
        this.formLoading = true;
        try {
                await this.$store.dispatch('bonuses/updateSpinsBonus', this.editBonus);
                this.$toast.add({ severity: 'success', summary: this.$t('bonus_created'), life: 4000 }); 
                this.$router.push('/spins-bonuses/view');
                await this.dispatchSpinsBonuses();
            } catch (error) {
                
                this.$toast.add({ severity: 'error', 
                summary: this.$t('just_error'),
                detail: error,
                life: 4000 }); 
            } finally{
                this.formLoading = false;
            }
      },
        async dispatchSpinsBonuses() {
            try {
                await this.$store.dispatch('bonuses/awaitGetSpinsBonuses');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
                detail: error,
                life: 4000 });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.form-block{
    //max-height: 214px;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    span{
      display: flex;
      align-items: center;
    }
    .title-input {
      flex: 0 0 200px;
      display: flex;
      //justify-content: flex-end;
      @media (max-width:500px){
        flex: 0 0 100px;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
      }
    }
    .wrapper-input {
      //flex: 0 0 200px;
     // width: 100%;
    flex: 1 1 auto;
    @media (max-width:500px){
      flex: 1 1 100%;
    }
    }
    
    .v-input{
      min-width: 170px;
      max-width: max-content;
        margin-right: 5px;
        //&:not(:last-child) {
        //}
        margin-bottom: 6px;
        &.error--text{
            margin-bottom: 0;
        }
    }
    &_height {
        flex-direction: column;
        align-items: stretch;
    }
  
    &__header {
    }
  
    &__inputs {
        display: flex;
        flex-wrap: wrap;
        .v-input{
            @media (minh:500px){
                max-width: 170px;
                
            }
        }
    }
  }
</style>